html {
  font-size: $ank-ui-font-size;
}

.login-parent {
  height: 100%;
}
.login-page {

  font-family: $ank-ui-font;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #f5f5f5;

  .login-banner {
    border-radius: 5px;
    text-align: center;
  }

  .imgbanner {
    height: 8rem;
    max-width: 100%;
  }

  .btn-secondary {
    background-color: $input-bg;
    color: $input-color;
  }

  .login-form {

    min-width: 30rem;
    input::placeholder {
      color: $ank-ui-color-font-placeholder;
    }
  }

  .authent-locale {
    width:auto;
  }
  .authent-form {
    padding: 2rem 4rem 1rem 4rem;
    border-radius: 0.5rem;
    margin-top: 1rem;
    background-color: white;
    box-shadow: #dddddd 0 4px 10px;
  }
  .authent-form--forget {
    margin: 0;

  }
  .message--error {
    color: $error;
  }

  .authent-buttons {
    margin-top: 1rem;
  }

  .authent-password {
    margin-top: 0.5rem;
  }



  input {
    border-color: $ank-ui-doc-edit-attribute-color-border;
    box-shadow: none;

    &.k-input:invalid, &.k-textbox:invalid, &.k-input.k-textbox:invalid {
      color: $ank-ui-color-font-placeholder;
      border-color: $ank-ui-doc-edit-attribute-color-border;

      &:focus {
        border-color: $ank-ui-doc-edit-attribute-hover-color;
        box-shadow: $ank-ui-doc-edit-attribute-hover-shadow;
      }
    }
  }

  .k-input:focus, .k-textbox:focus, .k-input.k-textbox:focus, input:focus {
    border-color: $ank-ui-doc-edit-attribute-hover-color;
    box-shadow: $ank-ui-doc-edit-attribute-hover-shadow;
  }
}


@media (max-width:34rem){

  .login-page {
    .login-parent {
      width: 100%;
    }
    .login-form {
      min-width: unset;
      width: 100%;
      .authent-component {
        .authent-bottom {
          flex-direction: column;
          > .k-widget, >.k-button {
            width:100%;
          }
          .k-input {
            text-align: center;
            display: inline-block;
          }
        }
      }
    }
  }
}


@media (max-width:20rem){
  .login-page {
    .authent-component {
      .authent-form {
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
}
}